import React from "react";
import {theme} from '../components/theme/theme';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Image from 'mui-image';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button';
import Bg1 from "../images/background/abst-formLarge.svg"
import BorderBgBottom from "../images/background/wave-haikei-bottom.svg"
import BorderBgTop from "../images/background/wave-haikei.svg"
import {isMobile} from 'react-device-detect';
import {offers} from '../components/indexes/offers';

import loadable from '@loadable/component';
const Layout = loadable(() => import('../components/common/Layout'));
const ServiceRequest = loadable(() => import('../components/ServiceRequest'));
const Seo = loadable(() => import('../components/seo'));

const useStyles = makeStyles({
  root: {
    background: theme.palette.text.hint,
  },
  bg:{
    backgroundImage: `url(${Bg1})`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    backgroundPosition: '30px 60px',
  },
  borderBottom:{
    backgroundImage: `url(${BorderBgBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center bottom',
    border: 0,
    padding: '100px 0'
  },
  borderTop:{
    backgroundImage: `url(${BorderBgTop})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    border: 0,
    minHeight: '95vh',
  },
  promotions: {
    minHeight: '95vh',
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignItems: 'space-between',
    paddingBottom: '5vh',
    paddingTop: '5vh',
    justifyContent: 'space-evenly'
  },
  promotion: {
    width: '100%',
    maxWidth: 1280,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: 0,
    marginTop: 50,
    marginBottom: 10,
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '60vh',
    background: 'transparent'
  },
  card: {
    minWidth: '480px',
    paddingTop: 40,
    padding: 40,
    flex: '1 1 50%',
    display: 'flex',
    alignContent: 'stretch',
    alignItems: 'stretch',
    background: theme.palette.common.white,
    borderRadius: 10,
    },
    mobileCard: {
      minWidth: '280px',
      paddingTop: 30,
      paddingBottom: 5,
      padding: 12,
      flex: '1 1 42%',
      display: 'flex',
      alignContent: 'stretch',
      alignItems: 'stretch',
      background: theme.palette.common.white,
      borderRadius: 0,
    },
    CardContent:{
      minWidth: 280,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    CardActions:{
      padding: 0,
      paddingTop: 40,
    },
    media: {
      minHeight: 400,
      minWidth: '400px',
      flex: '1 1 50%',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      background: 'transparent',
    },
    mobileMedia: {
      minHeight: 340,
      minWidth: '340px',
      flex: '1 1 58%',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      background: 'transparent',
    },
  image: {
    flex: '1 0 auto',
    minHeight: 400,
    background: 'transparent',
    '&:nth-child(1)':{
      backgroundColor: 'transparent',
      minHeight: 400,
    },
  },
  img: {
      backgroundColor: 'transparent',
      minHeight: 400,
    },
  title: {
    paddingTop: 240,
    paddingBottom: 10,
    maxWidth: 600,
  },
  characteristic: {
    marginTop: 35,
    color: theme.palette.primary.dark
  },
  value: {
    marginTop: 35,
    color: theme.palette.primary.main
  },
  titleWrap: {
    padding: 120,
    paddingTop: 300,
    paddingBottom: 20
  },
});

const Ofertas = ({location, page}) => {
  const classes = useStyles();
  const cardClass = (isMobile) ? 'mobileCard' : 'card';
  const cardMediaClass = (isMobile) ? 'mobileMedia' : 'media';
  const anchor = location.pathname.replace('/ofertas/', '').replace('/', '');
  const offer = offers.filter((o) => (o.anchor === decodeURI(anchor)))[0];
  
  return (
    <Layout>
      <Seo title="Offertas" />
      <Box className={classes.root}>
      <Box className={classes.bg}>
      <Box className={classes.borderTop}>
      <Box className={classes.borderBottom}>
      <Container maxWidth='xl' className={classes.promotions}>
          <Paper  elevation={0} className={classes.promotion} id={anchor}>
          {offer &&
            <div className={classes[cardMediaClass]} ><div className={classes.image}><Image className={classes.img} color='transparent' contain aspectRatio={(1/1)} src={offer.image} /></div></div>
          }
          <Card elevation={0}  className={classes[cardClass] } >

            <CardContent className={classes.CardContent}>
              <div>
              <Typography gutterBottom variant="h6" component="h4" color='secondary'>
                {offer && offer.cat}
              </Typography>
              <Typography gutterBottom variant="h2" component="h1"  color='primary'>
                {offer && offer.title}
              </Typography>
              <Typography paragraph={true}  variant="subtitle1" component="p">
                {offer && offer.excerpt}
              </Typography>
              </div>
              <div>
              <div className={classes.characteristic}>
              <Typography variant="overline" component="p">Caracteristicas</Typography>
              <Typography gutterBottom paragraph variant="body1"  component="p">
                {offer && offer.characteristics}
              </Typography>
              <Typography gutterBottom paragraph variant="body1" component="p">
                {offer && offer.deliverable}
              </Typography>
              </div>
              <div className={classes.value}>
              <Typography gutterBottom variant="h5" component="p">
                {offer && offer.price}
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                {offer && offer.valid}
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                {offer && offer.requirements}
              </Typography>
              </div>
              </div>
              <CardActions className={classes.CardActions}>
                <Button  href='#solicitar'  color="secondary" startIcon={<CheckCircleIcon />} variant="contained">
                  Solicitar
                </Button>
                </CardActions>
            </CardContent>
          </Card>
          </Paper>

          </Container>
      </Box>
      </Box>
      </Box>
      </Box>
      <ServiceRequest />
      </Layout>
  )
}
export default Ofertas;
